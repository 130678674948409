import Alert from '@material-ui/lab/Alert';
import React from 'react'

const Alertt = ({severity, message, close}) => {
  return (
    <Alert onClose={close} severity={severity}>{message}</Alert>
  )
}

export default Alertt
